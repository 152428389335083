define("save-the-date/controllers/index", ["exports", "jquery", "firebase/app", "firebase/firestore"], function (_exports, _jquery, _app, _firestore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      nameChanged() {
        const name = document.getElementById("name").value;
        if (name) {
          (0, _jquery.default)('#required_field_name').hide();
        } else {
          (0, _jquery.default)('#required_field_name').show();
        }
      },
      emailChanged() {
        const email = document.getElementById("email").value;
        if (email) {
          (0, _jquery.default)('#required_field_email').hide();
        } else {
          (0, _jquery.default)('#required_field_email').show();
        }
      },
      phoneChanged() {
        const phone = document.getElementById("phone").value;
        if (phone) {
          (0, _jquery.default)('#required_field_phone').hide();
        } else {
          (0, _jquery.default)('#required_field_phone').show();
        }
      },
      showRSVP() {
        (0, _jquery.default)(".container").hide();
        (0, _jquery.default)(".success").hide();
        (0, _jquery.default)(".rsvp_container").show().addClass("fade-in");
        (0, _jquery.default)(".form").show();
      },
      closeRSVP() {
        (0, _jquery.default)(".rsvp_container").hide();
        (0, _jquery.default)(".success").hide();
        (0, _jquery.default)(".form").hide();
        (0, _jquery.default)(".container").show().addClass("fade-in");
      },
      submitForm() {
        const name = document.getElementById("name").value;
        const email = document.getElementById("email").value;
        const phone = document.getElementById("phone").value;
        const selectElement = document.getElementById("number");
        const noOfGuests = selectElement.value;
        const db = _app.default.firestore();
        const collection = db.collection('rsvp');
        const docId = email;
        if (name) {
          (0, _jquery.default)('#required_field_name').hide();
          if (email) {
            (0, _jquery.default)('#required_field_email').hide();
            if (this.isValidEmail(email)) {
              (0, _jquery.default)('#required_field_email_not_valid').hide();
              if (phone) {
                (0, _jquery.default)('#required_field_phone').hide();
                const data = {
                  name: name,
                  email: email,
                  phone: phone,
                  guests: noOfGuests
                };
                collection.doc(docId).set(data, {
                  merge: true
                }).then(() => {
                  (0, _jquery.default)("html, body").animate({
                    scrollTop: 0
                  }, "slow");
                  (0, _jquery.default)(".form").hide();
                  (0, _jquery.default)(".success").show().addClass("fade-in");
                  document.getElementById("name").value = "";
                  document.getElementById("email").value = "";
                  document.getElementById("phone").value = "";
                }).catch(error => {
                  alert("Unable to add RSVP.");
                  console.error("Error adding or updating data: ", error);
                });
              } else {
                (0, _jquery.default)('#required_field_phone').show();
              }
            } else {
              (0, _jquery.default)('#required_field_email_not_valid').show();
            }
          } else {
            (0, _jquery.default)('#required_field_email').show();
          }
        } else {
          (0, _jquery.default)('#required_field_name').show();
        }
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    }
  });
  _exports.default = _default;
});