define("save-the-date/controllers/colourpalette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    functionsArray: [{
      title: "Engagement",
      subtitle: "Love Hues & Dapper Suits",
      textArray: ["On this Valentine's Day, love will light our way.  \n In blingy hues, we'll sway and play! ", "Ladies in gowns and sarees that bling,  \n In shades they love, their style will sing.  \n Gents in suits, so dapper and fine,  \n Matching ties with partners, a romantic sign.  \n A night of dance and food galore,  \n A memory to cherish forevermore."],
      count: 9,
      colorArray: [],
      isDJImage: false,
      isHavingImages: true,
      imageArray: ["/engagement/1.JPG", "/engagement/new_11.jpg", "/engagement/3.JPG", "/engagement/4.JPG", "/engagement/5.JPG", "/engagement/6.JPG", "/engagement/7.png", "/engagement/8.JPG", "/engagement/9.JPG", "/engagement/10.png", "/engagement/new_2.png", "/engagement/12.png"]
    }, {
      title: "Mehndi",
      subtitle: "Bright Blooms & Henna Dreams",
      textArray: ["For our Mehandi, in daylight's gleam,  \n Orange, magenta, ink blue, red's dream.  \n Colors vivid, just for you,  \n Or any bright shade that you pursue!", "Traditions with joy, we'll intertwine,  \n Hennaed hands, a beauty so divine.  \n Partners' love, singles' cheer,  \n In vibrant colors, hearts will draw near."],
      count: 12,
      colorArray: ['#FF6600', 'hsl(285, 100%, 60%)', '#0713ee', '#ff0018', '#00cf35', '#ff008c', 'hsl(61, 80%, 40%)', 'hsl(330, 100%, 50%)', 'hsl(120, 100%, 25%)', '#00f7ff', '#ffff46', '#FF00FF'],
      isDJImage: false,
      isHavingImages: false,
      imageArray: []
    }, {
      title: "Dj Jaago Night",
      subtitle: "Black Elegance & Fulkari Splendor",
      textArray: ["Get ready to groove, it's DJ Jaago night,  \n In stylish blacks, we'll set the floor alight.  \n Unstoppable beats, we'll dance till dawn,  \n On DJ tunes and Dhol, our spirits drawn.", "But as we transition to the Jaago's embrace,  \n Let's bring tradition to this lively space.  \n Phulkari dupattas, vibrant and bold,  \n A touch of heritage, a sight to behold.", "Ladies, pull out your Phulkari delight,  \n If an extra one's there, make someone's night bright.  \n Hand it to a child, partner, or a friend so true,  \n In the Jaago's spirit, let the colors imbue!"],
      colorArray: ["#000000", "#010101", "#040404", "#080808", "#080808", "#101010"],
      count: 6,
      isHavingImages: false,
      isDJImage: true,
      imageArray: ["/dj/phulkari_3.jpeg", "/dj/phulkari_4.jpeg", "/dj/phulkari_55.jpg", "/dj/phulkari_5.png", "/dj/phulkari_44.jpg", "/dj/phulkari_6.png"]
    }, {
      title: "Haldi",
      subtitle: "Golden Gleam & Ivory Bliss",
      textArray: ["For Haldi's glow, in traditions we'll bind,  \n Wear yellows and whites, the colors so kind.  \n Shades of sunshine, mixed with whites' grace,  \n In this sweet blend, let the joy embrace.", "Traditional attire, a timeless sight,  \n With yellows and whites, we'll shine so bright.  \n On Haldi's day, let the colors play,  \n In yellow and white, we'll light the way!"],
      count: 11,
      colorArray: ["rgb(255, 253, 208)", "rgb(255, 215, 10)", "rgb(255, 255, 240)", "rgb(251, 255, 105)", "rgb(249, 231, 140)", "rgb(253, 254, 214)", "rgb(247, 246, 81)", "rgb(254, 255, 165)", "rgb(254, 251, 143)", "rgb(260, 230, 105)", "rgb(253, 242, 120)", "rgb(251, 225, 75)"],
      isHavingImages: false,
      isDJImage: false,
      isHavingBlingColors: false,
      imageArray: []
    }, {
      title: "Wedding",
      subtitle: "Pastel Promise",
      textArray: ["On our wedding day, a dream so bright,  \n Creating a scene, so beautifully light.  \n In soft lavender or baby blue,  \n Or any other pastel hues loved by you.", "In every smile and every glance,  \n Pastel love will sweetly dance.  \n A day of love, in colors so vast,  \n Our wedding day, a memory that'll forever last."],
      colorArray: ["#cfc3c3", "#d9dad9", "#ddcbcc", "#dadae4", "#d9d4e8", "#e2ddd7", "#e2c6c2", "#e3eeec", "#e3d0e0", "#e1a798", "#e6cbcc", "#c5d9a1", "#bbc0bd", "#a0c3d2", "#9bd5cc", "#99e3db", "#bdd6ee", "#eae2e0", "#b5eaf2", "#cfc3c3", "#e0f8f9", "#f6c7b3", "rgb(253,250,215)", "rgb(202,224,230)", "rgb(229,200,157)", "rgb(229,246,207)", "#e3eeec", "#c5d9a1", "#bdd6ee", "#ddcbcc"],
      count: 13,
      isDJImage: false,
      isHavingImages: false,
      imageArray: []
    }]
  });
  _exports.default = _default;
});