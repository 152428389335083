define("save-the-date/adapters/application", ["exports", "emberfire/adapters/firestore"], function (_exports, _firestore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _firestore.default.extend({
    enablePersistence: true,
    persistenceSettings: {
      synchronizeTabs: true
    }
  });
  _exports.default = _default;
});